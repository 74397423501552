<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h1 class="title">Veldu það sem hentar þér best</h1>
        <h4 class="description">
          Fríar uppfærslur fylgja hverri áskrift.
        </h4>
        <h4 class="description">
          Fyrirtæki með +50 manns vinsamlegast hafið samband við tolspor@tolspor.is og við setjum upp tilboð.
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-primary"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Stóri</h1>
          <img class="card-img" src="img/card-primary.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">25-50 notendur</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <li class="list-group-item">24/7 Support</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>31.990</h3>
            <h5 class="text-on-back">31.990</h5>
            <p class="plan">Stór fyrirtæki</p>
          </div>
          <!-- <base-button slot="footer" round type="primary" class="btn-just-icon">
            Get started
          </base-button> -->
        </card>
      </div>

      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-success card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Miðjan</h1>
          <img class="card-img" src="img/card-success.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">11-24 notendur</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <li class="list-group-item">24/7 Support</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>19.990</h3>
            <h5 class="text-on-back">19.990</h5>
            <p class="plan">Meðalstór fyrirtæki</p>
          </div>

          <!-- <base-button slot="footer" round type="success" class="btn-just-icon">
            Get started
          </base-button> -->
        </card>
      </div>

      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-warning card-raised card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Litli</h1>
          <img class="card-img" src="img/card-warning.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">2-10 notendur</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <!-- <li class="list-group-item">130 emails</li>
            <li class="list-group-item">24/7 Support</li> -->
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>9.990</h3>
            <h5 class="text-on-back">9.990</h5>
            <p class="plan">Lítil fyrirtæki</p>
          </div>

          <!-- <base-button slot="footer" round type="warning" class="btn-just-icon">
            Get started
          </base-button> -->
        </card>
      </div>
      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-danger card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">Einn</h1>
          <img class="card-img" src="img/card-danger.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">1 notandi</li>
            <li class="list-group-item">Ótakmarkaður fjöldi tækja</li>
            <!-- <li class="list-group-item">No Support</li> -->
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>kr.</span>4.990</h3>
            <h5 class="text-on-back">4.990</h5>
            <p class="plan">Einyrkjar</p>
          </div>

          <!-- <base-button slot="footer" round type="danger" class="btn-just-icon">
            Get started
          </base-button> -->
        </card>
      </div>
    </div>
    <!-- <div class="col-md-12 mt-5"><h3 class="title">Professional Plan</h3></div>
    <div class="row">
      <div class="col-md-4">
        <p>
          Premium pricing is the strategy of consistently pricing at, or near,
          the high end of the possible price range to help attract
          status-conscious consumers. The high pricing of a premium product ...
        </p>
      </div>
      <div class="col-md-6 ml-auto">
        <base-progress
          type="warning"
          :show-value="false"
          badge="500GB"
          :value="75"
        />

        <base-progress
          type="primary"
          :show-value="false"
          badge="4 years"
          :value="50"
        />
      </div>
    </div> -->
  </div>
</template>
<script>

export default {
  name: 'pricing-page',
};
</script>
<style></style>
